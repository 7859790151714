import React from "react";
import { ContactUs } from "../components/ContactUs/ContactUs.js";
import Home from "../components/Home/Home.js";
import InfoSection from "../components/InfoSection/InfoSection.js";
import Layout from "../components/Layout/Layout.js";
import MiddleText from "../components/MiddleText/MiddleText.js";
import Qualities from "../components/Qualities/Qualities.js";
import TeamSection from "../components/TeamSection/TeamSection.js";
import { Helmet } from "react-helmet";

const Index = () => {
    return (
        <>
            <Helmet htmlAttributes={{ lang: "pl" }}>
                <meta charSet="utf-8" />
                <title>OakDevices</title>
                <meta
                    name="description"
                    content="Oakdevices - transform Your Business Into The Company Of The Future"
                />
            </Helmet>
            <Layout>
                <Home />

                <Qualities />

                <MiddleText width={"80%"} mBottom={50}>
                    Whether it is IoT, drivers, controllers, consumer
                    electronics, security or microprocessors, you can achieve it
                    all in a timeframe that suits you.
                </MiddleText>

                <InfoSection />

                <MiddleText width={"75%"} mBottom={30}>
                    Tell us the story of your project, starting with the idea.
                    Tell us what is currently the biggest problem of the
                    project. Let's decide together if you lack:
                </MiddleText>

                <TeamSection />

                <MiddleText width={"50%"} mBottom={120}>
                    Once we have that defined, we invite you to talk about the
                    details, both technical and business.
                </MiddleText>

                <ContactUs id="contact-us" />
            </Layout>
        </>
    );
};

export default Index;

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import {
    Container,
    BackgroundImageContainer,
    MobileBgContainer,
    MainContent,
    TextField,
    TitleContainer,
    H1Container,
    ButtonContainer,
    GradientTitle,
    GuyImageContainer,
    HomeGuyImage,
    LeadTextContainer,
    LeadText,
} from "./Home.styles";
import { Button } from "../Button/Button";
import SocialMedia from "./SocialMedia/SocialMedia";

const Home = () => {
    return (
        <>
            <Container>
                <BackgroundImageContainer>
                    <StaticImage
                        src="../../images/top-background.png"
                        objectFit="fill"
                        placeholder="blurred"
                        style={{ width: "100%", height: "100%" }}
                        quality={100}
                    />
                </BackgroundImageContainer>
                <MainContent>
                    <TextField>
                        <TitleContainer>
                            <H1Container>
                                Do you need to bring your{" "}
                                <GradientTitle>embedded project</GradientTitle>{" "}
                                to completion quickly and efficiently?{" "}
                            </H1Container>
                            <MobileBgContainer>
                                <StaticImage
                                    src="../../images/home-guy.png"
                                    objectFit="fill"
                                    placeholder="blurred"
                                    // style={{ width: "100%", height: "100%" }}
                                    style={{ width: "60%" }}
                                    quality={100}
                                />
                                <StaticImage
                                    src="../../images/top-bg-mobile.png"
                                    placeholder="blurred"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        position: "absolute",
                                        zIndex: "-1",
                                    }}
                                    quality={100}
                                />
                            </MobileBgContainer>
                            <ButtonContainer>
                                <Button to="#contact-us" text="Contact" />
                            </ButtonContainer>
                            <SocialMedia />
                        </TitleContainer>
                    </TextField>
                    <GuyImageContainer>
                        <HomeGuyImage>
                            <StaticImage
                                src="../../images/home-guy.png"
                                // width={550}
                                layout="fullWidth"
                                placeholder="blurred"
                                quality={100}
                            />
                        </HomeGuyImage>
                    </GuyImageContainer>
                </MainContent>
            </Container>
            <LeadTextContainer>
                <LeadText>
                    Your project is at the implementation stage and you would
                    like to accelerate its finalization, or maybe you are stuck
                    in the project and the deadline is approaching. Sometimes
                    this happens, the problem may be an incomplete team of
                    developers that you can very easily recruit for your
                    unfinished project.
                </LeadText>
            </LeadTextContainer>
        </>
    );
};

export default Home;

import { Container, Text } from "./Button.styles";
import React from "react";

export const Button = ({ text, to }) => {
    return (
        <Container to={to}>
            <Text>{text}</Text>
        </Container>
    );
};

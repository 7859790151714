import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 1600px;

    @media (max-width: 880px) {
        flex-flow: column;
    }
`;

export const PersonBox = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
`;

export const TextBox = styled.div`
    width: 75%;

    @media (max-width: 880px) {
        text-align: center;
        margin-bottom: 50px;
    }
`;

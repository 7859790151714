import React from "react";
import { Container, Text } from "./MiddleText.styles";

const MiddleText = ({ width, children, mBottom }) => {
    return (
        <Container mBottom={mBottom}>
            <Text width={width}>{children}</Text>{" "}
        </Container>
    );
};

export default MiddleText;

import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: 890px) {
        margin-bottom: -100px;
    }
`;

export const Content = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    max-width: 1600px;
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    max-width: 1600px;

    @media (max-width: 890px) {
        margin-bottom: 100px;
        flex-flow: ${(props) => (props.reverse ? "column-reverse" : "column")};
        gap: 20px;
    }
`;

export const RowBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    /* border: 2px solid black; */
    @media (max-width: 890px) {
        width: 100%;
    }
`;

export const BgImage = styled.div`
    /* width: 100%; */
    position: absolute;
    z-index: -1;
    @media (max-width: 890px) {
        display: none;
    }
`;

export const TextBox = styled.h5`
    position: relative;
    /* border: 2px solid green; */
    width: 68%;
    left: 3%;
    top: -2%;
    @media (max-width: 890px) {
        display: none;
    }
`;

export const MobileBgImage = styled.div`
    width: 100%;
    display: none;
    @media (max-width: 890px) {
        display: flex;
        justify-content: center;
    }
`;

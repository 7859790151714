import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 120px 0px;

    @media (max-width: 880px) {
        margin-top: 75px;
        margin-bottom: ${(props) => props.mBottom}px;
    }
`;

export const Text = styled.h2`
    width: ${(props) => props.width};
    text-align: center;
    @media (max-width: 880px) {
        width: 90%;
    }
`;

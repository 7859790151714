import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import {
    Container,
    Content,
    Row,
    RowBox,
    BgImage,
    TextBox,
    MobileBgImage,
} from "./InfoSection.styles";

const InfoSection = () => {
    return (
        <Container>
            <Content>
                <Row reverse>
                    <RowBox>
                        <BgImage>
                            <StaticImage
                                src="../../images/InfoSection/bg1.png"
                                placeholder="blurred"
                            />
                        </BgImage>
                        <TextBox>
                            Does your embedded project meet modern software
                            standards? The multitude of technologies and
                            solutions on the market gives you a headache?
                            Competition imposes faster pace? Let us help you!
                            Our talented team can breathe new life into your
                            current product and help with migration to newer,
                            more efficient and easier to manage code.
                        </TextBox>
                        <MobileBgImage>
                            <StaticImage
                                src="../../images/InfoSection/mobile-bg1.png"
                                placeholder="blurred"
                            />
                        </MobileBgImage>
                    </RowBox>
                    <RowBox>
                        <StaticImage
                            src="../../images/InfoSection/pic1.png"
                            placeholder="blurred"
                        />
                    </RowBox>
                </Row>
                <Row>
                    <RowBox>
                        <StaticImage
                            src="../../images/InfoSection/pic2.png"
                            placeholder="blurred"
                        />
                    </RowBox>
                    <RowBox>
                        <BgImage>
                            <StaticImage
                                src="../../images/InfoSection/bg2.png"
                                placeholder="blurred"
                            />
                        </BgImage>
                        <TextBox>
                            Is your project just starting or do you just have an
                            idea for a new product? Our team of experts will
                            help you with hardware and software requirements. We
                            deal with complex implementations of electronic
                            devices from the idea to the finished production
                            process and provide support to our customers
                            throughout the product life cycle.
                        </TextBox>
                        <MobileBgImage>
                            <StaticImage
                                src="../../images/InfoSection/mobile-bg2.png"
                                placeholder="blurred"
                            />
                        </MobileBgImage>
                    </RowBox>
                </Row>
                <Row reverse>
                    <RowBox>
                        <BgImage>
                            <StaticImage
                                src="../../images/InfoSection/bg3.png"
                                placeholder="blurred"
                            />
                        </BgImage>
                        <TextBox>
                            Do you have a ready-made product but its price
                            frightens customers away? Do you want to improve
                            your competitiveness or increase your profits? Let
                            our team take a look at your device and
                            manufacturing process and we will help you find
                            optimization.
                        </TextBox>
                        <MobileBgImage>
                            <StaticImage
                                src="../../images/InfoSection/mobile-bg3.png"
                                placeholder="blurred"
                            />
                        </MobileBgImage>
                    </RowBox>
                    <RowBox>
                        <StaticImage
                            src="../../images/InfoSection/pic3.png"
                            placeholder="blurred"
                        />
                    </RowBox>
                </Row>
                <Row>
                    <RowBox>
                        <StaticImage
                            src="../../images/InfoSection/pic4.png"
                            placeholder="blurred"
                        />
                    </RowBox>
                    <RowBox>
                        <BgImage>
                            <StaticImage
                                src="../../images/InfoSection/bg4.png"
                                placeholder="blurred"
                            />
                        </BgImage>
                        <TextBox>
                            Semiconductor shortages on the market force your
                            company to constantly change hardware and your
                            development team has problems with porting and
                            maintaining software on new platforms? Our experts
                            can help you to create hardware abstraction layers
                            for your products and optimize software deployment
                            and testing processes for all supported hardware
                            revisions of your product.
                        </TextBox>
                        <MobileBgImage>
                            <StaticImage
                                src="../../images/InfoSection/mobile-bg4.png"
                                placeholder="blurred"
                            />
                        </MobileBgImage>
                    </RowBox>
                </Row>
                <Row reverse>
                    <RowBox>
                        <BgImage>
                            <StaticImage
                                src="../../images/InfoSection/bg5.png"
                                placeholder="blurred"
                            />
                        </BgImage>
                        <TextBox>
                            You have an idea for an embedded project, but do not
                            know how to plan its development. Organizational
                            issues are bothering you? You can solve it very
                            quickly. It is enough that you have an initial idea
                            and you do not have to worry about its
                            implementation. Planning a team of developers, time
                            of execution and implementation of the project will
                            very easy from now on.
                        </TextBox>
                        <MobileBgImage>
                            <StaticImage
                                src="../../images/InfoSection/mobile-bg5.png"
                                placeholder="blurred"
                            />
                        </MobileBgImage>
                    </RowBox>
                    <RowBox>
                        <StaticImage
                            src="../../images/InfoSection/pic5.png"
                            placeholder="blurred"
                        />
                    </RowBox>
                </Row>
                <Row>
                    <RowBox>
                        <StaticImage
                            src="../../images/InfoSection/pic6.png"
                            placeholder="blurred"
                        />
                    </RowBox>
                    <RowBox>
                        <BgImage>
                            <StaticImage
                                src="../../images/InfoSection/bg6.png"
                                placeholder="blurred"
                            />
                        </BgImage>
                        <TextBox>
                            Your project has come to a standstill and you have
                            no idea how to continue it? Do not lose faith in its
                            success. The project is worth finishing and you can
                            do it with the help of additional developers.
                            Everything will go according to your assumptions and
                            you will achieve success.
                        </TextBox>
                        <MobileBgImage>
                            <StaticImage
                                src="../../images/InfoSection/mobile-bg6.png"
                                placeholder="blurred"
                            />
                        </MobileBgImage>
                    </RowBox>
                </Row>
            </Content>
        </Container>
    );
};

export default InfoSection;

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Container, Box, Stars } from "./SocialMedia.styles";

const SocialMedia = () => {
    return (
        <Container>
            <Box href="https://www.facebook.com/OakfusionSoftwareHouse">
                <StaticImage
                    src="../../../images/SocialMedia/fb-btn.png"
                    placeholder="blurred"
                />
            </Box>
            <Box href="https://www.instagram.com/oakdevices/">
                <StaticImage
                    src="../../../images/SocialMedia/insta-btn.png"
                    placeholder="blurred"
                />
            </Box>
            <Box href="https://www.linkedin.com/company/oakdevices/about/">
                <StaticImage
                    src="../../../images/SocialMedia/link-btn.png"
                    placeholder="blurred"
                />
            </Box>
            <Stars>
                <StaticImage
                    src="../../../images/stars.png"
                    placeholder="blurred"
                />
            </Stars>
        </Container>
    );
};

export default SocialMedia;

import styled from "styled-components";
import gradient from "../../images/gradient.png";

export const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

export const SubContainer = styled.div`
    width: 100%;
    padding: 0px 20px;
    max-width: 1600px;
    display: flex;

    @media (max-width: 890px) {
        padding: 0;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
`;

export const BgImage = styled.div`
    position: absolute;
    z-index: -1;
    /* background-color: #ff0; */
    width: 100%;
    height: 100%;
    /* border: 1px solid grey; */
    border-radius: 5%;
    backdrop-filter: blur(25px);
    /* @media (max-width: 890px) {
        display: none;
    } */
`;

export const BallContainer = styled.div`
    position: absolute;
    z-index: -2;
    border: 10px solid black;
    width: 100%;
    height: 100%;
`;

export const Ball = styled.div`
    position: absolute;
    z-index: -2;
    top: -20px;
    right: -20px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    /* background: linear-gradient; */
    /* background: linear-gradient(35deg, #e66465, #9198e5); */
    background: url(${gradient});
`;

export const Ball2 = styled.div`
    position: absolute;
    z-index: -2;
    bottom: 0;
    left: 0;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* background: linear-gradient; */
    /* background: linear-gradient(35deg, #e66465, #9198e5); */
    background: url(${gradient});
`;

export const ImageContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    @media (max-width: 890px) {
        width: 80%;
    }
`;

export const QualitiesContainer = styled.div`
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid black; */
    @media (max-width: 890px) {
        width: 80%;
    }
`;

export const TextContainer = styled.div`
    /* width: 500px; */
    width: 85%;
    /* height: 60%; */
    display: flex;
    flex-flow: column;
    justify-content: center;
    /* border: 2px solid black; */
`;

export const H5 = styled.h5`
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 35px;
    @media (max-width: 1100px) {
        margin-bottom: 10px;
        margin-top: 25px;
    }
    @media (max-width: 1100px) {
        margin-bottom: 5px;
        margin-top: 15px;
    }
`;

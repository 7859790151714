import React, { useState } from "react";
import { useFormik } from "formik";
import emailjs from "@emailjs/browser";
import {
    Container,
    Left,
    Form,
    Input,
    TextArea,
    Text,
    Error,
    ButtonWrapper,
    ButtonContainer,
    Label,
    Title,
    ButtonText,
} from "./ContactUs.styles";
import ContactLoader from "./ContactLoader";
import ContactMessage from "./ContactMessage";
import ReCaptcha from "./ReCaptcha";

const validate = (values) => {
    const errors = {};
    if (!values.name) {
        errors.name = "Required";
    }

    if (!values.company) {
        errors.company = "Required";
    }

    if (!values.email) {
        errors.email = "Required";
    } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
        errors.email = "Invalid email address";
    }

    if (!values.message) {
        errors.message = "Required";
    } else if (values.message.length < 10) {
        errors.message = "Must be at least 10 characters";
    }
    return errors;
};

export const ContactUs = () => {
    const [loader, setLoader] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [success, setSuccess] = useState(true);
    const [captchaVerified, setCaptchaVerified] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            phoneNumber: "",
            company: "",
            message: "",
            from_page: "OakDevices-campaign",
        },
        validate,
        onSubmit: (values) => {
            if (captchaVerified) {
                formik.resetForm();
                setLoader(true);
                emailjs
                    .send(
                        "service_oakfusion",
                        "template_default",
                        values,
                        "q-NvkNe0iUoTYQftj"
                    )
                    .then(
                        (success) => {
                            setLoader(false);
                            setSuccess(true);
                            setShowMessage(true);
                        },
                        (error) => {
                            setLoader(false);
                            setSuccess(false);
                            setShowMessage(true);
                        }
                    );
            } else {
                alert("Please fill in CAPTCHA to prove you're not a robot");
            }
        },
    });
    return (
        <Container id="contact-us">
            <Left>
                <Title>Contact Us</Title>
                <Text>
                    Let's talk. Tell us what you need and we will look for
                    solutions.
                </Text>
            </Left>
            <Form
                onSubmit={formik.handleSubmit}
                isActive={!loader && !showMessage}
            >
                <Label>
                    {formik.values.name ? "Name" : String.fromCharCode(160)}
                </Label>
                <Input
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Your name"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
                <Error>
                    {formik.touched.name && formik.errors.name
                        ? formik.errors.name
                        : String.fromCharCode(160)}
                </Error>
                <Label>
                    {formik.values.company
                        ? "Company name"
                        : String.fromCharCode(160)}
                </Label>
                <Input
                    id="company"
                    name="company"
                    type="text"
                    onChange={formik.handleChange}
                    placeholder="Company name"
                    onBlur={formik.handleBlur}
                    value={formik.values.company}
                />
                <Error>
                    {formik.touched.company && formik.errors.company
                        ? formik.errors.company
                        : String.fromCharCode(160)}
                </Error>
                <Label>
                    {formik.values.email
                        ? "Email address"
                        : String.fromCharCode(160)}
                </Label>
                <Input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email address"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.email}
                />
                <Error>
                    {formik.touched.email && formik.errors.email
                        ? formik.errors.email
                        : String.fromCharCode(160)}
                </Error>
                <Label>
                    {formik.values.phoneNumber
                        ? "Phone number"
                        : String.fromCharCode(160)}
                </Label>
                <Input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="text"
                    placeholder="Phone number - optional"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.phoneNumber}
                />
                <Error>{String.fromCharCode(160)}</Error>
                <Label>
                    {formik.values.message
                        ? "Your message"
                        : String.fromCharCode(160)}
                </Label>
                <TextArea
                    id="message"
                    name="message"
                    type="text"
                    placeholder="Your message"
                    maxlength="300"
                    rows="1"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.message}
                />
                <Error>
                    {formik.touched.message && formik.errors.message
                        ? formik.errors.message
                        : String.fromCharCode(160)}
                </Error>
                <ButtonContainer>
                    <ReCaptcha onChange={setCaptchaVerified} />
                    <ButtonWrapper type="submit">
                        <ButtonText>Send a message</ButtonText>
                    </ButtonWrapper>
                </ButtonContainer>
            </Form>
            {loader && <ContactLoader />}
            {showMessage && <ContactMessage success={success} />}
        </Container>
    );
};

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Container, Content, PersonBox, TextBox } from "./TeamSection.styles";

const TeamSection = () => {
    return (
        <Container>
            <Content>
                <PersonBox>
                    <StaticImage
                        src="../../images/Team/po.png"
                        placeholder="blurred"
                    />
                    <TextBox>
                        Product owner - to manage the product from start to
                        finish.
                    </TextBox>
                </PersonBox>
                <PersonBox>
                    <StaticImage
                        src="../../images/Team/pm.png"
                        placeholder="blurred"
                    />
                    <TextBox>
                        PM / SM - to manage the project leading to this product.
                    </TextBox>
                </PersonBox>
                <PersonBox>
                    <StaticImage
                        src="../../images/Team/teachlead.png"
                        placeholder="blurred"
                    />
                    <TextBox>
                        A technical leader - to manage developers on a technical
                        level.
                    </TextBox>
                </PersonBox>
                <PersonBox>
                    <StaticImage
                        src="../../images/Team/developers.png"
                        placeholder="blurred"
                    />
                    <TextBox>
                        The developers themselves to do the work in the project.
                    </TextBox>
                </PersonBox>
                <PersonBox>
                    <StaticImage
                        src="../../images/Team/mark.png"
                        placeholder="blurred"
                    />
                    <TextBox>
                        Some key element of the project that you can't/won't do
                        yourself?
                    </TextBox>
                </PersonBox>
            </Content>
        </Container>
    );
};

export default TeamSection;

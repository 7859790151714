import styled from "styled-components";

export const Container = styled.div`
    position: relative;
    width: 100%;
    height: 1200px;
    display: flex;
    flex-flow: column;
    align-items: center;
    @media (max-width: 890px) {
        height: 850px;
    }
    @media (max-width: 690px) {
        height: 1250px;
    }
    @media (max-width: 490px) {
        height: 1200px;
    }
    @media (max-width: 390px) {
        height: 1150px;
    }
`;

export const BackgroundImageContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 60vw;
    top: -2.5vw;
    @media (max-width: 690px) {
        display: none;
    }
`;

export const MobileBgContainer = styled.div`
    width: 100%;
    display: none;

    @media (max-width: 690px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        margin-bottom: -20px;
        width: 100%;
    }
`;

export const MainContent = styled.div`
    position: relative;
    display: flex;
    height: max-content;
    width: 100%;
    margin-top: 50px;

    @media (max-width: 690px) {
        margin-top: 30px;
        justify-content: center;
    }
`;

export const TextField = styled.div`
    display: flex;
    flex-flow: column;
    width: 60%;

    @media (max-width: 690px) {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
`;

export const TitleContainer = styled.div`
    position: relative;
    height: max-content;
    top: 30%;
    left: 10%;
    width: 80%;
    @media (max-width: 690px) {
        position: inherit;
        width: 100%;
        text-align: center;
    }
`;

export const H1Container = styled.h1`
    @media (max-width: 690px) {
        padding: 0px 30px;
    }
`;

export const ButtonContainer = styled.div`
    position: relative;
    margin-top: 40px;
    margin-bottom: 100px;

    @media (max-width: 690px) {
        margin-bottom: 50px;
    }
`;

export const GradientTitle = styled.span`
    font-family: inherit;
    background: -webkit-linear-gradient(
        0deg,
        #d738bd 0%,
        #f283ad 27.78%,
        #f8b2ac 45.69%,
        #f97db0 75.49%,
        #af9add 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`;

export const GuyImageContainer = styled.div`
    display: flex;
    width: 40%;
    justify-content: center;

    @media (max-width: 690px) {
        display: none;
    }
`;

export const HomeGuyImage = styled.div`
    /* border: 2px solid black; */
    width: 85%;
    height: max-content;
`;

export const LeadTextContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    top: -200px;
    max-width: 2000px;
    margin-bottom: -150px;
`;

export const LeadText = styled.h2`
    width: 88%;
    text-align: center;
`;

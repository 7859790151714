import React from "react";
import Footer from "../Footer/Footer";
import GlobalStyles from "../../styles/GlobalStyles.js";

const Layout = ({ children }) => {
    return (
        <React.Fragment>
            <GlobalStyles />
            {children}
            <Footer />
        </React.Fragment>
    );
};

export default Layout;

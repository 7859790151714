import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    position: absolute;
    justify-content: space-between;
    width: 90vw;
    max-width: 900px;

    @media (max-width: 690px) {
        flex-flow: column;
        align-items: center;
        width: 100%;
        gap: 10px;
    }
`;

export const Box = styled.a`
    position: relative;
    display: flex;
    text-decoration: none;
    color: inherit;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
        color: inherit;
    }
`;

export const Stars = styled.div`
    position: absolute;
    top: 90px;
    left: -40px;

    @media (max-width: 690px) {
        top: 250px;
        left: 60px;
    }
`;

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import {
    Container,
    SubContainer,
    BgImage,
    Ball,
    Ball2,
    ImageContainer,
    QualitiesContainer,
    TextContainer,
    H5,
} from "./Qualities.styles";

const Qualities = () => {
    return (
        <Container>
            <SubContainer>
                <ImageContainer>
                    <StaticImage
                        src="../../images/qualities.png"
                        placeholder="blurred"
                    />
                </ImageContainer>
                <QualitiesContainer>
                    <BgImage>
                        {/* <StaticImage
                            src="../../images/qualities-bg.png"
                            placeholder="blurred"
                            width={650}
                        /> */}
                    </BgImage>
                    {/* <BallContainer> */}
                    <Ball />
                    <Ball2 />
                    {/* </BallContainer> */}
                    <TextContainer>
                        <H5>1.Recruiting time and cost.</H5>
                        <h5>
                            The level of difficulty in recruiting qualified
                            developers is increasing every year. Hiring proven
                            consultants is an increasingly popular alternative
                            for many companies.
                        </h5>
                        <H5>2.Scalability of the team.</H5>
                        <h5>
                            As the needs grow, we offer to quickly engage
                            additional programmers. Similarly, when a project is
                            nearing its end, there is no need to keep
                            programmers waiting for new projects.
                        </h5>
                        <H5>3.Matching competencies and team seniority.</H5>
                        <h5>
                            We offer consultants and specialists ready to
                            program in your chosen stack, without the need for
                            retraining.
                        </h5>
                        <H5>4.Quality guarantee. </H5>
                        <h5>
                            When you feel that a person does not fit into the
                            team or does not meet your expectations in any way
                            {" ->"} there is no need to conduct difficult repair
                            talks or even dismiss the employee. We meet such
                            problems and try to replace the consultant with a
                            more suitable one, ensuring quick knowledge
                            transfer.
                        </h5>
                        <H5>5.Smooth Project Implementation</H5>
                        <h5 style={{ marginBottom: "20px" }}>
                            If for any reason a team member is unable to
                            continue the project, we react immediately and
                            engage a replacement or look for other suitable
                            solutions.
                        </h5>
                    </TextContainer>
                </QualitiesContainer>
            </SubContainer>
        </Container>
    );
};

export default Qualities;

import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import {
    Container,
    Section,
    Header,
    BackgroundDesktop,
    BackgroundMobile,
} from "./Footer.styles.js";

const Footer = () => {
    return (
        <Container>
            <BackgroundMobile>
                <StaticImage
                    src="../../images/mobile-footer-background.png"
                    alt="gradient"
                    placeholder="blurred"
                    objectFit="cover"
                    layout="fullWidth"
                    style={{ height: "100%" }}
                    quality="100"
                />
            </BackgroundMobile>
            <BackgroundDesktop>
                <StaticImage
                    src="../../images/gradient-texture.png"
                    alt="gradient"
                    placeholder="blurred"
                    objectFit="cover"
                    style={{ height: "100%" }}
                    quality="100"
                />
            </BackgroundDesktop>
            <Section>
                <Header>Quick links</Header>
                <a href="https://oakdevices.com/#technology">Technologies</a>
                <a href="https://oakdevices.com/products">Products</a>
                <a href="https://oakdevices.com/case-studies">Case Studies</a>
                <a href="https://oakdevices.com/blog">Blog</a>
            </Section>
            <Section>
                <Header>Office Poland</Header>
                <p>Żmigrodzka 244, 51-131 Wrocław, Poland</p>
                <p>office@oakfusion.pl</p>
                <p>+48 797 743 064</p>
            </Section>
            <Section>
                <Header>Austria</Header>
                <p>
                    c/o Impact Hub Vienna Lindengasse 56/18-19 1070 Wien,
                    Austria
                </p>
                <p>vienna@oakfusion.com</p>
                <p>+43 69919064248</p>
            </Section>
            <Section>
                <Header>Nordic</Header>
                <p>Anckargripsgatan 3 211 19-SE Malmö Sweden</p>
                <p>office@oakfusion.pl</p>
            </Section>
        </Container>
    );
};

export default Footer;

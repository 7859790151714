import styled from "styled-components";

export const Container = styled.footer`
    height: 385px;
    display: grid;
    grid-template-columns: repeat(4, 21.25%);
    gap: 5%;
    justify-items: center;
    justify-content: center;
    padding: 80px 5% 0;
    position: relative;
    width: 100%;
    @media (max-width: 890px) {
        height: auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width: 890px) {
        width: 80%;
    }
`;

export const Header = styled.h3`
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 22px;
`;

export const BackgroundDesktop = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    @media (max-width: 890px) {
        display: none;
    }
`;

export const BackgroundMobile = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    display: none;
    @media (max-width: 890px) {
        display: block;
    }
`;
